
import { Component, Mixins } from 'vue-property-decorator';
import { UsePageHeaderMixin } from '../mixins/use-page-header.mixin';
import { MyAccountRoutes } from '@/enums/my-account-routes.enum';
import { createRouteWithLocaleParam } from '@/utils/create-route-with-locale-param';
import { UseCurrentLocaleMixin } from '../mixins/use-current-locale.mixin';

@Component({
  components: {},
})
export default class MyAccount extends Mixins(UsePageHeaderMixin, UseCurrentLocaleMixin) {
  routername = MyAccountRoutes;

  get headerHtml() {
    return `<h2 class="text-white mb-5">${this.$t('account.title')}</h2>

        `;
  }
  openRouterView(name: string) {
    if (this.$router?.currentRoute?.name?.includes(name)) {
      return;
    }
    const route = createRouteWithLocaleParam(name, this.localeCode, {});

    this.$router.push(route);
  }

  getActiveTab(routeName: string) {
    if (this.$router.currentRoute.name == routeName) {
      return true;
    }
  }
}
